<template>
  <div>
    <md-app md-waterfall md-mode="overlap">
      <md-app-toolbar class="md-large coverImagePan">
        <div class="md-toolbar-row">
          <h1 style="padding-top:10px;padding-left:5px;font-size:3em">Humangut.info</h1>
        </div>
      </md-app-toolbar>
      <md-app-content>
          <p style="font-size:1.3em">Humangut.info is the index of web resources mainly developed by the <b><a href="http://www.chenlab.medgenius.info/index.php" target="_blank">Chenlab</a></b>  to display the works carried out in the feild of the humangut domain.The main focus of this lab to identify genes and/or microbial species that are of clinical significance by computational methods and multi-omics data integration, and develop novel methods to manipulate them for the purpose of real-life applications.</p>
          <h2 style="font-size:1.8em">Tools and resources</h2>
          <p style="font-size:1.2em">Developed in collaboration with our colleagues worldwide, perform complex queries and analyse/visualise the data in different ways. You can work locally by downloading our data and software, or use our web services to access our resources programmatically.</p>
          <div class="md-layout md-gutter">                        
            <div class="md-layout-item ">
              <h2 style="font-size:2em">Resources</h2>
              <hr>
              <div>
                <h2> <a href="https://mvp.medgenius.info/home" target="_blank">Microbe Versus Phage (MVP) </a> : a microbe-phage interaction database</h2> 
                <p>
                  Phages invade microbes, accomplish host lysis, and are of vital importance in shaping the community structure of environmental microbiota.
                </p>
              </div>
              <div>
                <h2> <a href="https://gmrepo.humangut.info/" target="_blank">GMRepo</a>: a curated database of human gut metagenomes</h2>
                <p>
                  Its main features include:
extensive manual curation of meta-data of gut samples/runs,
grouping samples/runs according to their associated human phenotypes (i.e. health and diseases),
graphical selectors enabling easy and efficient access to all our collected data.
                </p>
              </div>
              <div>
                <h2> <a href="https://mgedb.comp-sysbio.org/#/" target="_blank">mMGE </a> : Human metagenomic eMGE database</h2>
                <p>mMGE integrates extrachromosomal mobile genetic elements(eMGEs) derived from more than 66,425 human metagenomic samples.</p>
              </div>
              <div>
                <h2> <a href="http://fmt2mice.humangut.info/#/" target="_blank">FMT2Mice</a> </h2>
                <p>Fecal microbiota transplant (FMT) of human fecal samples to germ-free (GF) mice is useful for establishing causal relationships between gut microbiota and human phenotypes.</p>
              </div>              
              <div>
                <h2> <a href="https://www.bugdrug-db.info/#/" target="_blank">BugDrugDB</a>  :  An online resource of Bacteria and drug interactions</h2>
                <p>With BugDrugDb we aim to map and associate bacteria and drug interactions,we try to achive this by
Curating interaction data from other databases,
Text mining,
Inferring interaction information from various experiments</p>
              </div>              
            </div>
            <div class="md-layout-item md-alignment-top">
              <h2 style="font-size:2em">Tools</h2>
              <hr>
              <div>
                <h2> <a href="https://www.evolgenius.info/evolview/#login" target="_blank">Evolview</a>:an online phylogenetic tree viewer and customization tool </h2>
                <p>Evolview is an online visualization and management tool for customized and annotated phylogenetic trees. It allows users to visualize phylogenetic trees in various formats, customize the trees through built-in functions and user-supplied datasets and export the customization results to publication-ready figures.</p>
              </div>
              <!-- <div>
                <h2> <a href="https://www.evolgenius.info/evolview-v2/#login" target="_blank">Evolview v2</a> </h2>
                <p></p>
              </div>               -->
            </div>
          </div>
      <Fotter/>
      </md-app-content>
    </md-app>   
  </div>
</template>

<style lang="scss" scoped>
  .md-app {
    // max-height: 800px;
    border: 1px solid rgba(#000, .12);
  }

  .coverImagePan {
    color: #333333;
    width:100%; 
    background-image: url(../assets/hgut.png); /* your image */
    background-repeat: no-repeat;
    background-size: 100%;
    height: 300px;;
  }

   // Demo purposes only
  .md-drawer {
    width: 230px;
    max-width: calc(100vw - 125px);
  }
</style>

<script>
import Fotter from './Fotter'

export default {
  name: 'Overlap',
  components:{
    Fotter
  },
  data: () => ({
    menuVisible: false
  })
}
</script>

